import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Header from "../components/header"
import TopHat from "../components/tophat"
import Content from "../components/content"
import Footer from "../components/footer"
import SEO from "../components/seo"
import logo from "../images/logo.png"

const Page = ({data, pageContext}) => {
    const page = data.graphAPI.page
    console.log(data)
    return (
        <Layout>
            <SEO title={page.title} />
            <TopHat />
            <div className="main">
                <Header district={pageContext.district}  />
                <div className="main-content">
                    <div className="title-section">
                        <small style={{display: 'inline-block',paddingBottom: '1rem'}}>{pageContext.district !== '-1' ? (<>Hays WCID {pageContext.district}</>) : (<></>)}</small>
                        <h1 className="title is-size-1">{page.title}</h1>
                    </div>
                    <Content content={page.content} district={pageContext.district} />


                </div>
                <Footer district={pageContext.district}></Footer>
            </div>
        </Layout>
    )
}

export default Page

export const pageQuery = graphql`
    query pageById($id: ID!) {
        graphAPI {
            page(id: $id) {
                id
                title
                content {
                    ... on GRAPHAPI_ComponentPageBaseContent {
                        Body
                        minHeight
                        id
                    }
                    ... on GRAPHAPI_ComponentPageImage {
                        id
                        Placement
                        Width
                        Image {
                            url
                            updated_at
                            documentFile {
                                childImageSharp {
                                    fluid(maxWidth: 2048){
                                        ...GatsbyImageSharpFluid_tracedSVG
                                    }
                                }
                            }
                        }
                    }
                    ... on GRAPHAPI_ComponentPageLinkCardWithImage {
                        id
                        style
                        image {
                          updated_at
                          url
                          documentFile {
                                childImageSharp {
                                    fluid(maxWidth: 512){
                                        ...GatsbyImageSharpFluid_tracedSVG
                                    }
                                }
                          }
                        }
                        url
                        linkbody: body
                        aspectRatio
                    }
                    ... on GRAPHAPI_ComponentPageSplitContentImage {
                        id
                        splitbody: body
                        image {
                          updated_at
                          url
                          documentFile {
                                childImageSharp {
                                    fluid(maxWidth: 2048){
                                        ...GatsbyImageSharpFluid_tracedSVG
                                    }
                                }
                          }
                        }
                    }
                    ... on GRAPHAPI_ComponentPageGallery {
                        id
                        images {
                          url
                          updated_at
                          documentFile {
                                childImageSharp {
                                    fluid(maxWidth: 2048){
                                        ...GatsbyImageSharpFluid_tracedSVG
                                    }
                                }
                          }
                        }
                    }
                    ... on GRAPHAPI_ComponentPageLinkList {
                        id
                        link {
                            id
                            title
                            style
                            type
                            url
                            document {
                                id
                                url
                                updated_at
                                documentFile {
                                    publicURL
                                    prettySize
                                }
                            }
                        }
                    }
                }
            }
        } 
    }
`


